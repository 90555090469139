<template>
  <layout :tituloPagina="`Network | Routers | Edición | ${router.nombre} | Interfaces LAN`">
    <div class="row">
      <div class="col-md-10">
        <div class="text-right">
          <router-link
            :to="{ name: 'nuevaLanRouter' }"
            class="btn btn-success btn-sm"
          >
            <i class="mdi mdi-plus-thick"></i>
            Nueva
          </router-link>
        </div>
        <br />
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title text-truncate w-100">
              Lista de interfaces LAN del router {{ router.nombre }}
            </h4>
            <div>
              <a href="#" onclick="return false;" @click="cerrar()">
                <i class="mdi mdi-close text-dark"></i>
              </a>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-hover table-nowrap">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Descripción</th>
                    <th>Interfaz LAN</th>
                    <th>Dirección IP / CIDR</th>
                    <th>NAT</th>
                    <th>PPPoE</th>
                    <th>Servicio PPPoE</th>
                    <th class="text-center" style="width: 180px">Acción</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="lan in lans" :key="lan.id">
                    <td>{{ lan.id }}</td>
                    <td>{{ lan.descripcion }}</td>
                    <td>{{ lan.name }}</td>
                    <td>{{ lan.direccion_ip }}</td>
                    <td>
                      <span class="badge bg-success bg-gradient" v-if="lan.nat == true">
                        <i class="mdi mdi-check-bold"></i>
                      </span>
                    </td>
                    <td>
                      <span
                        class="badge bg-success bg-gradient"
                        v-if="lan.pppoe == true"
                      >
                        <i class="mdi mdi-check-bold"></i>
                      </span>
                    </td>
                    <td>{{ lan.nombre_servicio_pppoe }}</td>
                    <td>
                      <router-link
                        :to="{
                          name: 'edicionLanRouter',
                          params: { id_lan: lan.id }
                        }"
                        class="btn btn-warning btn-sm"
                      >
                        <i class="mdi mdi-content-save-edit-outline"></i>
                        Edición
                      </router-link>
                      <button
                        class="btn btn-danger btn-sm"
                        @click="preguntaEliminar(lan)"
                      >
                        <i class="mdi mdi-trash-can-outline"></i>
                        Eliminar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="text-right">
          <button class="btn btn-secondary btn-sm" @click="atras()">
            <i class="mdi mdi-chevron-left"></i>
            Atrás
          </button>
        </div>
      </div>
    </div>
    <br>
  </layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import RouterSrv from '@/services/RouterSrv.js'
import LanRouterSrv from '@/services/LanRouterSrv.js'
import Swal from 'sweetalert2'

export default {
  name: 'ListadoLansRouter',
  components: { Layout },
  data() {
    return {
      baseUrl: window.location.origin, 
      router: { id: null },
      lans: []
    }
  },

  created: function() {
    var self = this

    // Carga de la relación del ISP con el router especificado
    this.idRouter = this.$route.params.id

    this.cargarRouter()
    this.refrescarLans()
  },

  methods: {
    atras: function() {
      this.$router.go(-1)
    },

    cerrar: function() {
      this.atras()
    },

    cargarRouter: function(callback) {
      var self = this
      this.idRouter = this.$route.params.id

      return RouterSrv.routerJSON(this.idRouter).then(response => {
        self.router = response.data

        if (
          self.router.interfaces == undefined ||
          self.router.interfaces.length == 0
        ) {
          iu.msg.warning(
            'Conecta tu router a ArgusBlack para que se sincronicen las interfaces y puedas registrar las LAN'
          )
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : ''
        console.log(error)
      })
    },

    preguntaEliminar: function(lan) {
      var self = this
      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Eliminar interfaz",
        html: '¿Deseas eliminar la LAN <strong>'+ lan.descripcion+'</strong>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          LanRouterSrv.eliminar(lan.id).then(response => {
            swal.fire("Eliminado!", "Se eliminó correctamente", "success");
            self.refrescarLans()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo eliminar la LAN'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    refrescarLans: function() {
      var self = this

      LanRouterSrv.lansJSON(self.idRouter).then(response => {
        self.lans = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    }
  },
}
</script>

<style scoped>

</style>